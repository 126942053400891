/*
Adapted from: https://gitlab.com/zapier/fb-embed-frontend/-/blob/staging/packages/service/src/hooks/use-zapier-swr.ts
*/
import useSWR from "swr";

import makeZapierRequest from "@/utils/makeZapierRequest";

const fetcher = async (url: string) => {
  return makeZapierRequest({
    url: url,
    init: {
      method: "GET",
    },
  }).then((res) => res.json());
};

// Helper/wrapper function over standard useSWR, but includes default to revalidateOnFocus: false
// and also defining the fetcher function

export default function useZapierSWR<T>(url: string | null) {
  // TODO: we should use react-query instead of SWR
  return useSWR<T>(url, fetcher, { revalidateOnFocus: false });
}
