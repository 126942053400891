import { isEmpty } from "lodash";
import OpenAI from "openai";

import {
  ActionConfig,
  FunctionModel,
  SpecLinks,
} from "@/ai-actions/server/code-utils/types";
import { USE_ZAPIER_PROXY } from "@/utils/constants";
import { versionlessSelectedApi } from "@/utils/helpers";

import { InputField, normalizeInputFields } from "./InputField";
import KeyValue from "./KeyValue";

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

/* Action visibility values much match the values that the ACE backend API expects */
export enum ActionVisibility {
  USER = "U",
  ACCOUNT = "A",
  PUBLIC = "P",
}

/* Action status values much match the values that the ACE backend API expects */
export enum ActionStatus {
  DRAFT = "D",
  PENDING_REVIEW = "R",
  PUBLISHED = "P",
}

/* Action origin_type values must match the values that the ACE backend API expects */
export enum OriginType {
  USER = "USER",
  COPIED = "COPIED",
  AI_ASSISTED = "AI_A",
}

export enum ActionType {
  REQUEST = "REQUEST",
  CODE = "CODE",
}

export enum ActionImplementationTag {
  DEVELOPMENT = "dev",
  PRODUCTION = "prod",
}

/* These keys must match the keys that the ACE backend API expects */
interface ActionRequestJson {
  extension_type?: ActionType;
  method: HttpMethod;
  url: string;
  name: string;
  description: string;
  selected_api: string;
  app_name?: string;
  query_params: Record<string, string> | string;
  headers: Record<string, string> | string;
  body_template: string | null;
  function_schema: FunctionModel | null;
  specs: SpecLinks;
  chat_history: OpenAI.Chat.ChatCompletionMessageParam[];
  config: ActionConfig;
  params: InputField[] | string;
  visibility: ActionVisibility;
  status: ActionStatus;
  origin_type: string;
  origin_id: string;
  origin_data: Record<string, string> | string;
  creation_attempt_id?: string | null;
  parent: number | null;
  bindings: ActionBinding[];
  implementation_ids?: string[];
  development_implementation?: ActionImplementationDetailResponseJson | {};
  production_implementation?: ActionImplementationDetailResponseJson | {};
  latest_implementation?: ActionImplementationDetailResponseJson | {};
}

/* Contains the read only fields */
export interface ActionResponseJson extends ActionRequestJson {
  id: number | undefined;
  customuser_id: number;
  created_at: string;
  updated_at: string;
  query_params: Record<string, string>;
  headers: Record<string, string>;
  params: InputField[];
  children: ActionResponseJson[];
  bindings: ActionBinding[];
  implementation_ids: string[];
  latest_implementation: ActionImplementationDetailResponseJson | {};
  development_implementation: ActionImplementationDetailResponseJson | {};
  production_implementation: ActionImplementationDetailResponseJson | {};
}

interface ActionImplementationDetailResponseJson {
  id: string | undefined;
  registry_id: string | null;
  is_production: boolean;
  made_production_at: string | null;

  params: InputField[];
  function_schema: FunctionModel | null;
}

interface ActionImplementationRequestJson {
  id?: string | undefined;
  registry_id?: string | null;
  is_production?: boolean;
  made_production_at?: string | null;

  params?: InputField[] | string;
  function_schema?: FunctionModel | null;
}

export interface ActionBinding {
  id?: string;
  domain: string | null;
  path: string | null;
  authentication_id: number | null;
  inputs: Record<string, any>;
  config: Record<string, any>;
}

type ActionConstructorProps = {
  id?: number;
  type?: ActionType;
  method?: HttpMethod;
  url?: string;
  name?: string;
  description?: string;
  selectedApi?: string;
  appName?: string;
  queryParams?: KeyValue[];
  headers?: KeyValue[];
  body?: string;
  functionSchema?: FunctionModel;
  specs?: SpecLinks;
  chatHistory?: OpenAI.Chat.ChatCompletionMessageParam[];
  config?: ActionConfig;
  inputs?: InputField[];
  visibility?: ActionVisibility;
  status?: ActionStatus;
  customuserId?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  originType?: string;
  originId?: string;
  originData?: Record<string, string> | string;
  creationAttemptId?: string;
  parent?: number | null;
  children?: Action[];
  bindings?: ActionBinding[];
  implementationIds?: string[];
  latestImplementation?: ActionImplementationDetailResponseJson | {};
  developmentImplementation?: ActionImplementationDetailResponseJson | {};
  productionImplementation?: ActionImplementationDetailResponseJson | {};
};

type ActionImplementationConstructorProps = {
  id?: string | undefined;
  registryId?: string | null;
  isProduction?: boolean;
  madeProductionAt?: string | null;
  functionSchema?: FunctionModel | null;
  inputs?: InputField[];
};

export type OriginFields = {
  originType: string;
  originId: string | null;
  originData: Record<string, string> | string;
};

export interface CodeExtensionCompatible {
  // Represents the minimum set of data required for runtime execution of Code Extensions
  id: number | string | undefined;
  functionSchema: FunctionModel | null;
  selectedApi: string;
  customuserId: number | null;
}

export default class Action implements CodeExtensionCompatible {
  id: number | undefined;
  type: ActionType;
  method: HttpMethod;
  url: string;
  name: string;
  description: string;
  selectedApi: string;
  queryParams: KeyValue[];
  headers: KeyValue[];
  body: string;
  functionSchema: FunctionModel | null;
  specs: SpecLinks;
  chatHistory: OpenAI.Chat.ChatCompletionMessageParam[];
  config: ActionConfig;
  inputs: InputField[];
  visibility: ActionVisibility;
  status: ActionStatus;
  customuserId: number | null;
  createdAt: string | null;
  updatedAt: string | null;
  originType: string;
  originId: string | null;
  originData: Record<string, string> | string;
  creationAttemptId: string;
  parent: number | null;
  children: Action[];
  bindings: ActionBinding[];
  implementationIds: string[];
  developmentImplementation?: ActionImplementation | Record<PropertyKey, never>;
  productionImplementation?: ActionImplementation | Record<PropertyKey, never>;
  latestImplementation: ActionImplementation | Record<PropertyKey, never>;

  constructor(props: ActionConstructorProps = {}) {
    const {
      id = undefined,
      type = ActionType.REQUEST,
      method = HttpMethod.GET,
      url = "https://",
      name = "Untitled Action",
      description = "",
      selectedApi = "",
      appName = "",
      queryParams = [{ key: "", value: "" }],
      headers = [{ key: "", value: "" }],
      body = "",
      functionSchema = null,
      specs = [],
      chatHistory = [],
      config = {},
      inputs = [],
      visibility = ActionVisibility.ACCOUNT,
      status = ActionStatus.DRAFT,
      customuserId = null,
      createdAt = null,
      updatedAt = null,
      originType = OriginType.USER,
      originId = "",
      originData = JSON.parse("{}"),
      creationAttemptId,
      parent = null,
      children = [],
      bindings = [],
      implementationIds = [],
      latestImplementation = {},
      productionImplementation = {},
      developmentImplementation = {},
    } = props;

    const defaultDescription = appName
      ? `Description for your custom ${appName} action`
      : "Description for your custom action";

    this.id = id;
    this.type = type;
    this.method = method;
    this.url = url;
    this.name = name;
    // Set a default description to avoid the user
    // having validation errors a newly opened form
    this.description = description === "" ? defaultDescription : description;
    this.selectedApi = versionlessSelectedApi(selectedApi);
    this.queryParams = queryParams;
    this.headers = headers;
    this.body = body;
    this.functionSchema = functionSchema;
    this.specs = specs;
    this.chatHistory = chatHistory;
    this.config = config;
    this.inputs = inputs;
    this.visibility = visibility;
    this.status = status;
    this.customuserId = customuserId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.originType = originType;
    this.originId = originId;
    this.originData = originData;
    this.creationAttemptId = creationAttemptId || "";
    this.parent = parent;
    this.children = children || [];
    this.bindings = bindings || [];
    this.implementationIds = implementationIds || [];
    this.latestImplementation = latestImplementation || {};
    this.productionImplementation = productionImplementation || {};
    this.developmentImplementation = developmentImplementation || {};
  }

  static fromJson = (json: ActionResponseJson): Action => {
    if (!json) {
      return new Action();
    }

    return new Action({
      id: json.id,
      type: json.extension_type || ActionType.REQUEST,
      method: HttpMethod[json.method],
      url: json.url,
      name: json.name,
      description: json.description,
      selectedApi: json.selected_api,
      appName: json.app_name || "",
      queryParams: KeyValue.fromJson(json.query_params),
      headers: KeyValue.fromJson(json.headers),
      body:
        HttpMethod[json.method] !== HttpMethod.GET
          ? json.body_template || ""
          : "",
      functionSchema: json.function_schema || undefined,
      specs: json.specs,
      chatHistory: json.chat_history,
      config: json.config,
      inputs: normalizeInputFields(json.params),
      visibility: json.visibility,
      status: json.status,
      customuserId: json.customuser_id,
      createdAt: json.created_at,
      updatedAt: json.updated_at,
      originType: json.origin_type,
      originId: json.origin_id,
      originData: json.origin_data,
      creationAttemptId: json.creation_attempt_id || "",
      parent: json.parent,
      children: (json.children || []).map((child) => Action.fromJson(child)),
      bindings: json.bindings || [],
      // The || operator is only a defender and noop. `implementation_ids` and
      // `latest_implementation` are included in the json for Actions regardless
      // if they are lifecycle aware or not.
      implementationIds: json.implementation_ids || [],
      latestImplementation: isEmpty(json.latest_implementation)
        ? {}
        : // TS doesn't realize that `latest_implementation` is not empty due to the
          // `isEmpty` check above.
          ActionImplementation.fromJson(
            json.latest_implementation as ActionImplementationDetailResponseJson,
          ),
    });
  };

  static toJson = (action: Action): ActionRequestJson => {
    const filteredQueryParams = action.queryParams.filter(
      (q) => q.key && q.value,
    );

    const filteredHeaders = action.headers.filter(
      (h: KeyValue) => h.key && h.value,
    );

    const serializedInputs = normalizeInputFields(action.inputs || []);

    return Action.stringifyProperties({
      extension_type: action.type,
      method: action.method,
      url: action.url,
      name: action.name,
      description: action.description,
      selected_api: action.selectedApi,
      query_params: KeyValue.toJson(filteredQueryParams),
      headers: KeyValue.toJson(filteredHeaders),
      body_template: action.method !== HttpMethod.GET ? action.body : "",
      function_schema: action.functionSchema,
      specs: action.specs,
      chat_history: action.chatHistory,
      config: action.config,
      params: serializedInputs,
      visibility: action.visibility,
      status: action.status,
      origin_type: action.originType,
      origin_id: action.originId || "",
      origin_data: action.originData,
      creation_attempt_id:
        action.creationAttemptId === "" ? null : action.creationAttemptId,
      parent: action.parent,
      bindings: action.bindings,
      implementation_ids: action.implementationIds,
      latest_implementation: ActionImplementation.toJson(
        action.latestImplementation,
      ),
    });
  };

  // See convo here:
  // https://zapier.slack.com/archives/C04U829G58X/p1690860634457639
  // It's not entirely clear why, but apparently the monolith proxy requires
  // nested object/array properties to be stringified. This is a no-op if we're
  // going direct through the Next.js backend to the Django backend though.
  static stringifyProperties = (
    actionJson: ActionRequestJson,
  ): ActionRequestJson => {
    if (USE_ZAPIER_PROXY) {
      return {
        ...actionJson,
        query_params: JSON.stringify(actionJson.query_params),
        headers: JSON.stringify(actionJson.headers),
        params: JSON.stringify(actionJson.params),
        origin_data: JSON.stringify(actionJson.origin_data),
      };
    }
    return actionJson;
  };

  static getImplementationForTag(
    action: Action,
    tag: ActionImplementationTag,
  ): ActionImplementation | {} {
    switch (tag) {
      case ActionImplementationTag.DEVELOPMENT:
        return action.developmentImplementation || {};
      case ActionImplementationTag.PRODUCTION:
        return action.productionImplementation || {};
      default:
        return {};
    }
  }

  static isLifecycleAware(action: Action): boolean {
    return !isEmpty(action.latestImplementation);
  }
}

export class ActionImplementation {
  id: string | undefined;
  registryId: string | null;

  isProduction: boolean;
  madeProductionAt: string | null;

  functionSchema: FunctionModel | null;
  inputs: InputField[];

  constructor(props: ActionImplementationConstructorProps) {
    const {
      id = undefined,
      registryId = null,
      isProduction = false,
      madeProductionAt = null,
      functionSchema = null,
      inputs = [],
    } = props;

    this.id = id;
    this.registryId = registryId;
    this.isProduction = isProduction;
    this.madeProductionAt = madeProductionAt;

    this.inputs = inputs;
    this.functionSchema = functionSchema;
  }

  static fromJson = (
    json: ActionImplementationDetailResponseJson,
  ): ActionImplementation => {
    return new ActionImplementation({
      id: json.id,
      registryId: json.registry_id,

      isProduction: json.is_production,
      madeProductionAt: json.made_production_at,
      inputs: normalizeInputFields(json.params),

      functionSchema: json.function_schema,
    });
  };

  static toJson = (
    implementation: ActionImplementation | Record<PropertyKey, never>,
  ): ActionImplementationRequestJson => {
    if (isEmpty(implementation)) {
      return {};
    }

    const serializedInputs = normalizeInputFields(implementation.inputs || []);

    return ActionImplementation.stringifyProperties({
      id: implementation.id,
      registry_id: implementation.registryId,
      is_production: implementation.isProduction,
      made_production_at: implementation.madeProductionAt,
      params: serializedInputs,
      function_schema: implementation.functionSchema,
    });
  };

  // Note: this next paragraph is copied from `Action.stringifyProperties` above
  // See convo here:
  // https://zapier.slack.com/archives/C04U829G58X/p1690860634457639
  // It's not entirely clear why, but apparently the monolith proxy requires
  // nested object/array properties to be stringified. This is a no-op if we're
  // going direct through the Next.js backend to the Django backend though.
  static stringifyProperties = (
    actionImplementationJson: ActionImplementationRequestJson,
  ): ActionImplementationRequestJson => {
    if (USE_ZAPIER_PROXY) {
      return {
        ...actionImplementationJson,
        params: JSON.stringify(actionImplementationJson.params),
      };
    }
    return actionImplementationJson;
  };
}

export class ActionWithImplementationDataWrapper
  implements CodeExtensionCompatible
{
  id: number | undefined;
  selectedApi: string;
  customuserId: number | null;

  functionSchema: FunctionModel | null;
  inputs: InputField[];

  constructor(action: Action, implementation: ActionImplementation) {
    // Metadata comes from the Action
    this.id = action.id;
    this.selectedApi = action.selectedApi;
    this.customuserId = action.customuserId;

    // Runtime data comes from the Implementation
    this.functionSchema = implementation.functionSchema;
    this.inputs = implementation.inputs;
  }
}
