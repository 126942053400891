import humanizeString from "humanize-string";
import { type JSONSchema } from "json-schema-typed";

import { FunctionModel } from "@/ai-actions/server/code-utils/types";
import { PREFILL_ANNOTATION } from "@/ai-actions/server/single-app-action-codegen/constants";

/*
  Matches the "types" available in the Dev Platform [0].

  [0]: https://zapier.github.io/zapier-platform-schema/build/schema.html#fieldschema
*/
export enum DataType {
  STRING = "string",
  TEXT = "text",
  INTEGER = "integer",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATETIME = "datetime",
  PASSWORD = "password",
  COPY = "copy",
}

export type InputFieldValueType = string | boolean;

/*
  This field cannot be changed because they rely on an external dependency
  (they loosely match the Dev Platform [0]). The monolith will do any
  necessary validation on these fields, converting them to the appropriate
  internal shapes for execution.

  [0]: https://zapier.github.io/zapier-platform-schema/build/schema.html#fieldschema
*/
export interface InputField {
  key: string;
  label: string;
  helpText?: string;
  required: boolean;
  type: DataType;
  dynamic?: string; // this is the legacy prefill value
  choices?: string;
  placeholder?: string;
  format?: "SELECT"; // this is the future format for choices
  functionSchema?: FunctionModel;
}

export interface ParentField {
  key: string;
  children: InputField[];
}

export function normalizeInputField(
  inputField: Partial<InputField>,
): InputField {
  const choiceList = inputField.choices
    ?.split(",")
    .map((item) => item.trim())
    .join(",");
  return {
    key: inputField.key || "",
    label:
      inputField.label ||
      (inputField.key ? humanizeString(inputField.key) : ""),
    helpText: inputField.helpText || undefined,
    required: inputField.required || false,
    type: inputField.type || DataType.STRING,
    dynamic: inputField.dynamic || undefined,
    choices: choiceList || undefined,
    placeholder: inputField.placeholder || undefined,
    functionSchema: inputField.functionSchema || undefined,
  };
}

export function normalizeInputFields(inputFields: InputField[]): InputField[] {
  if (!inputFields || !Array.isArray(inputFields)) {
    return [];
  }
  return inputFields.map(normalizeInputField);
}

const jsonPropertyTypeToInputDataType: Record<string, DataType> = {
  string: DataType.STRING,
  integer: DataType.INTEGER,
  number: DataType.NUMBER,
  boolean: DataType.BOOLEAN,
};

function getInputFieldTypeFromJsonSchema(
  jsonSchema: JSONSchema,
): DataType | undefined {
  if (typeof jsonSchema === "boolean") {
    return undefined;
  }
  const type = jsonSchema.type;
  if (type === "array") {
    return undefined;
  }
  if (typeof type === "string") {
    return jsonPropertyTypeToInputDataType[type];
  }
  return undefined;
}

function getInputFieldFromJsonSchema(
  key: string,
  jsonSchema: JSONSchema,
): InputField | ParentField | undefined {
  const type = getInputFieldTypeFromJsonSchema(jsonSchema);

  if (!type) {
    if (typeof jsonSchema !== "boolean") {
      if (jsonSchema.type === "array" && jsonSchema.items) {
        const childInputFields = getInputFieldsFromJsonSchema(jsonSchema.items);
        if (childInputFields.length > 0) {
          return {
            key,
            children: childInputFields,
          };
        }
      }
    }
    return undefined;
  }

  if (typeof jsonSchema === "boolean") {
    return undefined;
  }

  const dynamic = jsonSchema[PREFILL_ANNOTATION];
  const placeholder = jsonSchema["placeholder"];
  const functionSchema = jsonSchema["functionSchema"];

  return {
    key,
    type,
    label: humanizeString(key),
    helpText: jsonSchema.description,
    required: false,
    placeholder: placeholder,
    dynamic,
    functionSchema,
  };
}

export function getInputFieldsFromJsonSchema(
  jsonSchema: JSONSchema,
): InputField[] {
  if (typeof jsonSchema === "boolean") {
    return [];
  }

  return Object.entries(jsonSchema.properties || {})
    .map(([key, property]) => {
      const inputField = getInputFieldFromJsonSchema(key, property);
      if (inputField) {
        if (jsonSchema.required && jsonSchema.required.includes(key)) {
          return {
            ...inputField,
            required: true,
          };
        }
      }
      return inputField;
    })
    .filter((inputField): inputField is InputField => !!inputField);
}
