export default class KeyValue {
  constructor(public key: string, public value: string) {}

  static fromJson = (json: Record<string, string>): KeyValue[] => {
    const pairs = Object.entries(json);
    if (pairs.length === 0) {
      return [new KeyValue("", "")];
    }
    return pairs.map(([key, value]) => new KeyValue(key, String(value)));
  };

  static toJson = (keyValuePairs: KeyValue[]): Record<string, string> => {
    return keyValuePairs.reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    }, {} as Record<string, string>);
  };
}
