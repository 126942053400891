/*
Get the CSRF token from the cookie or from the server.

Adapted from: https://gitlab.com/zapier/team-developer-platform/dev-platform/-/blob/main/web/frontend/assets/app/developer-v3/hooks/useCSRFToken.ts
*/
import { readCookie } from "@zapier/cookies";

import { ZAPIER_ORIGIN } from "./constants";

const getCSRFTokenFromCookie = () => readCookie("csrftoken");

async function getCSRFTokenFromServer(): Promise<string | null> {
  const url = new URL("/api/v3/csrf", ZAPIER_ORIGIN);
  const getCSRFHeader = await fetch(url.href, {
    credentials: "include",
    method: "HEAD",
  });
  const response = getCSRFHeader;
  const token = response.headers.get("x-upstreamcsrftoken");
  return token ? token : null;
}

const CSRFTokenPromise = async () => {
  const token = getCSRFTokenFromCookie();
  return token ? token : await getCSRFTokenFromServer();
};

export const getCSRFToken = async (): Promise<string | null> => {
  return await CSRFTokenPromise();
};
