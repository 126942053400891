import { HttpMethod } from "@/types/Action";

import { USE_ZAPIER_PROXY, ZAPIER_ORIGIN } from "./constants";
import { getCSRFToken } from "./useCSRFToken";

export class FetchError extends Error {
  constructor(
    statusText: string,
    public statusCode?: number,
    public error?: JSON | undefined,
  ) {
    super(statusText);
    this.name = "Fetch error.";
    this.statusCode = statusCode;
    this.error = error;
  }
}

/*
 * Makes an authenticated request and handles CSRF tokens for POST, PUT, PATCH and DELETE requests.
 * @param url - The request URL.
 * @param init - Optional RequestInit object.
 * @returns A promise that resolves to the response data.
 */
export default async function makeZapierRequest({
  url,
  init = {},
  throwError = true,
}: {
  url: string;
  init?: RequestInit;
  throwError?: boolean;
}) {
  if (!USE_ZAPIER_PROXY && ZAPIER_ORIGIN && !url.startsWith(ZAPIER_ORIGIN)) {
    // We're proxying through our Next.js backend, so remove the trailing slash:
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
  }
  const headers = new Headers(init?.headers);
  headers.set("Accept", "application/json");
  // Add CSRF token to POST, PUT, PATCH requests
  const method = init?.method;
  if (method && method !== HttpMethod.GET) {
    headers.set("Content-Type", "application/json");
    const csrfToken = await getCSRFToken();
    if (csrfToken) {
      headers.set("X-CSRFToken", csrfToken);
    }
  }

  const response = await fetch(url, {
    ...init,
    credentials: "include",
    headers: headers,
  });
  if (!response.ok && throwError) {
    const errorMessage = (await response.json()) || undefined;
    throw new FetchError(response.statusText, response.status, errorMessage);
  }
  return response;
}
