export const versionlessSelectedApi = (selectedApi: string) => {
  return selectedApi.split("@")[0];
};

/**
 * Returns the Zap-root ID from the contents of the referrer (referer) header,
 * or null.
 * @param referrer the contents of the referer header (if any)
 * @returns a number with the Zap-root ID, or null
 */
export const getZapRootIDFromURL = (referrer: string): number | null => {
  const pieces = referrer.split("/");
  if (pieces[3] !== "editor") {
    return null;
  }

  const root = Number.parseInt(pieces[4]);
  if (isNaN(root)) {
    return null;
  }
  return root;
};

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const dedupeAndTrimWhitespace = (query: string) =>
  query.replace(/(\s+)/g, " ").trim();

// Try to encode an object as the simplest query string possible. So string
// values are just key=value, but objects and some arrays get JSON encoded.
export function encodeObjectAsQueryString(obj: Record<string, any>) {
  const params = new URLSearchParams();
  for (const key in obj) {
    // We have to encode empty arrays since they won't show up at all as query
    // parameters. And we have to encode single item arrays since they will
    // just get decoded as a single scalar value. Not really much other way to
    // do this other than to encode/decode with a schema or just encode
    // everything.
    if (Array.isArray(obj[key]) && obj[key].length < 2) {
      params.append(key, JSON.stringify(obj[key]));
    } else {
      const values = Array.isArray(obj[key]) ? obj[key] : [obj[key]];
      for (const value of values) {
        if (value !== undefined) {
          if (typeof value === "string") {
            params.append(key, value);
          } else {
            params.append(key, JSON.stringify(value));
          }
        }
      }
    }
  }
  return params.toString();
}

// Decode a query string into an object. Everything is assumed to be JSON
// encoded, so you can pass any complex object, but it will also accept strings
// as a fallback so you can supply simple values as key=value.
export function decodeObjectFromQueryString(query: string) {
  const params = new URLSearchParams(query);
  const obj: Record<string, any> = {};
  for (const [key, value] of params.entries()) {
    let parsedValue = value;
    try {
      parsedValue = JSON.parse(value);
    } catch {
      // If the value is not JSON, just assume it's a string.
    }
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        obj[key].push(parsedValue);
      } else {
        obj[key] = [obj[key], parsedValue];
      }
    } else {
      obj[key] = parsedValue;
    }
  }
  return obj;
}

export function validatePayload(
  eventData: object,
  requiredProperties: string[],
) {
  try {
    for (const prop of requiredProperties) {
      if (!(prop in eventData)) {
        throw new Error(`Property '${prop}' is missing`);
      }
    }
  } catch (e) {
    throw new Error(`Error emitting event: autocode: ${e}`);
  }
}
